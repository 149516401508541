<template>
  <div>
    <div class="title">{{ $t("adnavPage.UserManagement") }}</div>
    <el-col :span="24" class="flex-between" style="margin-bottom: 10px;">
      <div></div>
      <el-button type="primary" @click="dialogShowUser = true">{{ $t("btnI18n.CreateUser") }}</el-button>
    </el-col>
    <el-table :data="tableAssets">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="$t('tableEmpty')"></div>
      </template>
      <el-table-column type="index" ></el-table-column>
      <el-table-column prop="address" :label="$t('systemPage.address')" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" :label="$t('formLabel.userName')" align="center"></el-table-column>
      <el-table-column prop="role" :label="$t('formLabel.userRole')" align="center">
        <template v-slot="scope">
          {{ scope.row.role | filterUser }}
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" :label="$t('systemPage.creationTime')" align="center" width="200">
        <template v-slot="scope">
          {{ scope.row.createdAt | timeStampToDate }}
        </template>
      </el-table-column>
    </el-table>
    <AdPagination :currentPage="tablePageCP" :pageSize="tablePagePS" :pageTotal="tableTotal" @handlePage="handlePage"></AdPagination>

    <el-dialog
        :close-on-click-modal="false"
        :title="$t('btnI18n.CreateUser')"
        :visible.sync="dialogShowUser"
        width="50%">
      <div class="text-center flex-column-center">
        <el-form
            ref="formUser"
            :model="formUser"
            :rules="formRules"
            label-width="160px" style="width: 100%" label-position="right">
          <el-form-item :label="$t('formLabel.userRole')" prop="role">
            <el-select v-model="formUser.role" placeholder="" style="width: 50%">
              <el-option
                  v-for="(item, index) in optionsRole"
                  :key="index"
                  :label="$t(item.label)"
                  :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('formLabel.userName')" prop="username">
            <el-input v-model="formUser.username" style="width: 50%" maxlength="20" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('formLabel.password')" prop="password">
            <el-input type="password" v-model="formUser.password" style="width: 50%" maxlength="20" clearable></el-input>
          </el-form-item>
          <el-form-item :label="$t('formLabel.rePassword')" prop="rePassword">
            <el-input type="password" v-model="formUser.rePassword" style="width: 50%" maxlength="20" clearable></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-btn">
          <el-button class="btn-black" @click="addUser()" :disabled="isSubmit">{{ $t('btnI18n.Confirms') }}</el-button>
          <el-button class="btn-black" @click="dialogShowUser = false;" :disabled="isSubmit">{{ $t('btnI18n.Cancel') }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AdPagination from "@/components/ad-pagination";
import rules from "@/plugins/rules";
import i18n from "@/plugins/i18n";

export default {
  name: "user-list",
  components: {
    AdPagination
  },
  data() {
    const validRePwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('loginPage.passwordErr')));
      } else if (value !== this.formUser.password) {
        callback(new Error(this.$t('loginPage.passwordReErr')));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false,
      tableAssets: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      optionsRole: [],
      optionsValRole: '',
      dialogShowUser: false,
      formUser: {
        username: "",
        password: "",
        rePassword: "",
        role: "1",
      },
      formRules: {
        username: [
          { required: true, trigger: 'blur', validator: rules.validateAccount }
        ],
        password: [
          { required: true, trigger: 'blur', validator: rules.validatePass }
        ],
        rePassword: [
          { required: true, trigger: 'blur', validator: validRePwd }
        ],
        role: [
          { required: true, trigger: 'blur' }
        ],
      },
      isSubmit: false,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.optionsRole = this.$enums.USER_ROLE;
  },
  methods: {
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.initData();
    },
    initData() {
      this.tableLoading = true;
      let params = { page: this.tablePageCP, pageSize: this.tablePagePS };
      this.$axios.get('/manage-admin/list', { params: params }).then((response) => {
        this.tableLoading = false;
        if (!response.code) {
          this.tableAssets = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
        }
      }).catch(error => {
        console.log(error);
        this.tableLoading = false;
      });
    },
    addUser() {
      this.isSubmit = true;
      const _this = this;
      this.$refs.formUser.validate((valid) => {
        if (valid) {
          delete this.formUser.rePassword;
          _this.$axios.post("/manage-admin/create", _this.formUser).then(res => {
            this.isSubmit = false;
            if (!res.code) {
              _this.$message.success(_this.$t("createdSuccess"));
              _this.dialogChange();
              _this.initData();
              _this.formUser.rePassword = "";
              _this.formUser.password = "";
              _this.formUser.username = "";
              _this.formUser.role = "1";
            }
          }).catch(() => {
            this.isSubmit = false;
          });
        } else {
          this.isSubmit = false;
        }
      });
    },
    dialogChange() {
      this.dialogShowUser = !this.dialogShowUser;
    },
  }
};
</script>

<style scoped lang="scss">
.list-opt {
  margin: 0 5px;
}
</style>
